// Miner.js
import { fetchData } from './api';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { timetodate, timetodatestring, roundedtime, TIMEAGO, HPS, CALC } from './main';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, LineController, BarController } from 'chart.js';
import { Line, Bar, Chart } from "react-chartjs-2";
import { PaymentSettings } from './PaymentSettings';
import { LOGO_SELECT, EVR, RVN, ZIL } from './COINS';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);
export const optionsHashrate = {
    scales: {
        A: {
            type: 'linear',
            position: 'left',
            min: 0, // Set the minimum value of the y-axis to 0
        },
        B: {
            type: 'linear',
            position: 'right',
            min: 0, // Set the minimum value of the y-axis to 0
        },
        xAxes: {
            ticks: {
                display: false, //this will remove only the label
            },
        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        }
    },
}
export const optionsPayments = {
    scales: {
        A: {
            type: 'linear',
            position: 'left',
            min: 0, // Set the minimum value of the y-axis to 0
        },
        xAxes: {
            ticks: {
                display: false, //this will remove only the label
            },
        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        }
    },
}

let minerID
let poolworkers = 0;
let workerpoolshares = 0;
let workerpoolhash = 0;
let minerpoolhash = 0;
let minerpoolshares = 0;
let soloworkers = 0;
let workersoloshares = 0;
let workersolohash = 0;
let minersolohash = 0;
let minersoloshares = 0;
let workers
let hash
let shares
let payout
let rigstatus
let lastDate
let lastPaid
let handleShow
let show
let handleClose

const Miner = () => {

  const { searchQuery } = useParams();
  if (searchQuery[0] === 'R' || 'm') {
      minerID = 'RVN';
  }
  if (searchQuery[0] === 'E') {
      minerID = 'EVR';
  }
  const [poolData, setData1] = useState('');
  const [soloData, setData2] = useState('');
  const [poolInfo, setData3] = useState('');
  const [soloInfo, setData4] = useState('');
  const [poolPay, setData5] = useState('');
  const [soloPay, setData6] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchAndProcessData = async () => {
      try {
        const fetchedData1 = await fetchData(`https://api.evrpool.com/EVR/pools/${minerID}/miners/${searchQuery}`);
        setData1(fetchedData1);
        const fetchedData2 = await fetchData(`https://api.evrpool.com/EVR/pools/${minerID}SOLO/miners/${searchQuery}`);
        setData2(fetchedData2);
        const fetchedData3 = await fetchData(`https://api.evrpool.com/EVR/pools/${minerID}`);
        setData3(fetchedData3);
        const fetchedData4 = await fetchData(`https://api.evrpool.com/EVR/pools/${minerID}SOLO`);
        setData4(fetchedData4);
        const fetchedData5 = await fetchData(`https://api.evrpool.com/EVR/pools/${minerID}/miners/${searchQuery}/payments`);
        setData5(fetchedData5);
        const fetchedData6 = await fetchData(`https://api.evrpool.com/EVR/pools/${minerID}SOLO/miners/${searchQuery}/payments`);
        setData6(fetchedData6);

        setIsLoading(false); // Data has been received, set isLoading to false
      } catch (error) {
        console.log('Error:', error);
        // Handle the error appropriately (e.g., show an error message)
        setIsLoading(false); // Error occurred, set isLoading to false
      }
    };
    fetchAndProcessData();
  }, [searchQuery]);
  if (isLoading) {
    return <p>Loading...</p>; // Render a loading indicator while data is being fetched
  }

  const row = [];
        if (poolData.performance !== undefined) {
		    let poolarray = Object.entries(poolData.performance.workers);
		    poolworkers = poolarray.length;
			workerpoolhash = 0;
		    for (const [key, value] of Object.entries(poolData.performance.workers)) {
              workerpoolhash += value.hashrate;
              workerpoolshares += value.sharesPerScond;
              workers = <td>{key}</td>;
              hash = <td>{HPS(value.hashrate)}</td>;
              shares = <td>{value.sharesPerSecond.toFixed(3)}</td>;
              payout = <td style={{color: "#d32e9d"}}>PPLNS</td>;
                if (value.hashrate == 0) {
                    rigstatus = <td style={{color: '#d61c10'}}>{TIMEAGO(poolData.performance.created)}</td>;
                    poolworkers = 0;
                }
                if (value.hashrate !== 0) {
                    rigstatus = <td style={{color: "#14d60a"}}> Online</td>;
                }
              row.push(<tr key={workers}>{workers}{hash}{shares}{payout}{rigstatus}</tr>);
            }
		}
		if (soloData.performance !== undefined) {
		    let soloarray = Object.entries(soloData.performance.workers);
		    soloworkers = soloarray.length;
			workersolohash = 0;
		    for (const [key, value] of Object.entries(soloData.performance.workers)) {
              workersolohash += (value.hashrate);
              workersoloshares += value.sharesPerSecond;
              workers = <td>{key}</td>;
              hash = <td>{HPS(value.hashrate)}</td>;
              shares = <td>{value.sharesPerSecond.toFixed(3)}</td>;
              payout = <td style={{color: "#5d2f86"}}>SOLO</td>;
                if (value.hashrate == 0) {
                    rigstatus = <td style={{color: '#d61c10'}}>{TIMEAGO(soloData.performance.created)}</td>;
                    soloworkers = 0;
                }
                if (value.hashrate !== 0) {
                    rigstatus = <td style={{color: "#14d60a"}}> Online</td>;
                }
              row.push(<tr key={workers}>{workers}{hash}{shares}{payout}{rigstatus}</tr>);
            }
		}

		// TTF
			//let netdiff = ((poolInfo.pool.networkStats.networkDifficulty + soloInfo.pool.networkStats.networkDifficulty)/2);
            let netdiff = poolInfo.pool.networkStats.networkDifficulty;
            let currentminerhash = (workerpoolhash + workersolohash);
            let TTF = ((netdiff * 2**32) / currentminerhash / 60).toFixed(0); //in minutes
        // Estimated Earnings
		    let estearnings = CALC(currentminerhash, 24, netdiff, 0.01);

        let time = new Array();

		let poolhash = new Array();
		let solohash = new Array();
		let totalhash = new Array();

		let poolshares = new Array();
		let soloshares = new Array();
		let totalshares = new Array();

		for (let i = 0; i < 144; i++) {
			time.push(timetodate((roundedtime(Date.now())) + (600000 * i)));
		}

		let poolcount = 0;
		for (let i = 0; i < 144; i++) {
			try {
				if (timetodate(poolData.performanceSamples[poolcount].created) === (time[i])) {
					let minerpoolhash = 0;
					let minerpoolshares = 0;
					for (const [key, value] of Object.entries(poolData.performanceSamples[poolcount].workers)) {
						minerpoolhash += value.hashrate;
						minerpoolshares += value.sharesPerSecond;
					}
					poolhash.push(minerpoolhash);
					poolshares.push(minerpoolshares);
					poolcount = poolcount + 1;
				}
				else {
					poolhash.push(0);
					poolshares.push(0);
				}
			}
			catch {
				poolhash.push(0);
				poolshares.push(0);
			}
		}
		let solocount = 0;
		for (let i = 0; i < 144; i++) {
			try {
				if (timetodate(soloData.performanceSamples[solocount].created) === (time[i])) {
					let minersolohash = 0;
					let minersoloshares = 0;
					for (const [key, value] of Object.entries(soloData.performanceSamples[solocount].workers)) {
						minersolohash += value.hashrate;
						minersoloshares += value.sharesPerSecond;
					}
					solohash.push(minersolohash);
					soloshares.push(minersoloshares);
					solocount = solocount + 1;
				}
				else {
					solohash.push(0);
					soloshares.push(0);
				}
			}
			catch {
				solohash.push(0);
				soloshares.push(0);
			}
		}
		for (let i = 0; i < 144; i++) {
			totalhash.push(poolhash[i] + solohash[i]);
			totalshares.push(poolshares[i] + soloshares[i]);
		}

		const minerhashchart = {
                	labels: time,
					datasets: [
					{
						type: "line",
						label: 'Total Hashrate',
						yAxisID: 'A',
						fill: false,
						backgroundColor: "rgba(0, 196, 255, 0.5)",
						borderColor: "rgba(0, 196, 255, 0.5)",
						data: totalhash,
						lineTension: 0.4
					},
                    {
						type: "line",
						label: 'SOLO Hashrate',
						yAxisID: 'A',
						fill: true,
						backgroundColor: "rgba(93, 47, 134, 0.5)",
                        borderColor: "rgba(93, 47, 134, 1)",
						data: solohash,
						lineTension: 0.4
					},
					{
						type: "line",
						label: 'PPLNS Hashrate',
						yAxisID: 'A',
						fill: true,
						backgroundColor: "rgba(211, 46, 157, 0.5)",
						borderColor: "rgba(211, 46, 157, 1)",
						data: poolhash,
						lineTension: 0.4
					},
					{
						type: "bar",
						barThickness: 'flex',
						label: 'Shares Per Second',
						yAxisID: 'B',
						fill: true,
						borderColor: "rgba(102, 220, 160, 0.75)",
						backgroundColor: "rgba(102, 220, 160, 0.75)",
						spanGaps: true,
						data: totalshares,
						lineTension: 0.4
					},
				],
                };

		let minerpayments = new Array();
		let paytime = new Array();
		let payamount = new Array();
		minerpayments = soloPay.concat(poolPay);
		// minerpayments = minerpayments.sort(function (a, b) {return b.created - a.created});
        minerpayments = minerpayments.sort(function (a, b) {return (a.created < b.created) ? 1 : ((a.created > b.created) ? -1 : 0)});

		if (minerpayments[0] !== undefined) {
		    for (let i = 0; i < minerpayments.length; i++) {
			paytime.push(timetodatestring(minerpayments[i].created));
			payamount.push(minerpayments[i].amount);
		    }
		    lastDate = (TIMEAGO(minerpayments[0].created));
		    lastPaid = ((minerpayments[0].amount).toFixed(1));
        }
		else if (minerpayments[0] === undefined) {
		    lastDate = 'N/A';
		    lastPaid = 'N/A';
        }

		const minerpaychart = {
                	labels: paytime.reverse(),
					datasets: [
					{
						type: "bar",
						barThickness: 'flex',
						label: 'Payment Amount',
						yAxisID: 'A',
						fill: true,
						borderColor: "rgba(102, 220, 160, 0.75)",
						backgroundColor: "rgba(102, 220, 160, 0.75)",
						spanGaps: true,
						data: payamount.reverse(),
						lineTension: 0.4
					},
				],
                };

  return (
    <>
  <div className="container">
    <div className="row" style={{ marginTop: 32, marginBottom: 16 }}>
      <div className="col-12">
        <h3 id="mineraddress">{LOGO_SELECT(minerID)}{" "}{searchQuery}</h3>
      </div>
    </div>
  </div>
  {/*- Page start */}
  <div className="container">
    <div className="card">
      <div className="card-body">
        <div className="row flex-nowrap">
          <div className="col-6">
            <h3 id="currenthash" style={{ color: "#E33746" }}>{HPS(workerpoolhash + workersolohash)}</h3>
          </div>
          <div className="col-6">
            <h3 id="workersonline" style={{ color: "#66dca0d9" }}>{poolworkers + soloworkers} Online</h3>
          </div>
        </div>
        <div className="card-body" key={searchQuery}><Chart type='bar' options={optionsHashrate} data={minerhashchart} style={{ width: "100%" }} key={searchQuery}/></div>
      </div>
      <div className="card-footer"></div>
    </div>
  </div>
  <div className="container">
    <div className="card-group">
      <div className="col-lg-6">
        <div className="card h-40 g-2">
          <div className="card-body">
              <div className="row" style={{ textAlign: "left" }}>
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="white"
                    className="bi bi-stopwatch-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584.531.531 0 0 0 .013-.012l.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354a.717.717 0 0 0-.012.012A6.973 6.973 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1h-3zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0z" />
                  </svg>
                  &nbsp;Time-To-Find:
                </h6>
              </div>
              <div className="row" style={{ textAlign: "right", marginBottom: "-16px" }}>
                <h6 id="ttf" style={{ color: "#00c4ff" }}>{TTF} minute(s)</h6>
              </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="card h-40 g-2">
          <div className="card-body">
              <div className="row" style={{ textAlign: "left" }}>
                <h6>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    fill="currentColor"
                    className="bi bi-calculator-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z" />
                  </svg>
                  &nbsp;Estimated Daily Earnings:
                </h6>
              </div>
              <div className="row" style={{ textAlign: "right", marginBottom: "-16px" }}>
                <h6 id="estearnings" style={{ color: "#00c4ff" }}>{estearnings.toFixed(1)} {minerID}</h6>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div className="card-group">
      <div className="col-lg-4">
        <div className="card h-40 g-2">
          <div className="card-body">
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                <h6>Pending Balance:</h6>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ textAlign: "center", marginBottom: "-16px" }}>
                <h6 id="currpending" style={{ color: "#00c4ff" }}>{(poolData.pendingBalance + soloData.pendingBalance).toFixed(1)} {minerID}</h6>
                <h6 id="currpendingzil" style={{ color: "#00c4ff" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card h-40 g-2">
          <div className="card-body">
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                <h6>Last Payout:</h6>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "-16px" }}>
              <div className="col" style={{ textAlign: "left" }}>
                <h6 id="lastdate" style={{ color: "#00c4ff" }}>{lastDate}</h6>
                <h6 id="lastpaidzil" style={{ color: "#00c4ff" }} />
              </div>
              <div className="col" style={{ textAlign: "right" }}>
                <h6 id="lastpaid" style={{ color: "#00c4ff" }}>{lastPaid} {minerID}</h6>
                <h6 id="lastpaidzil" style={{ color: "#00c4ff" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="card h-40 g-2">
          <div className="card-body">
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                <h6>
                    Total Paid:</h6>
              </div>
            </div>
            <div className="row">
              <div className="col" style={{ textAlign: "center", marginBottom: "-16px" }}>
                <h6 id="currpaid" style={{ color: "#00c4ff" }}>{(poolData.totalPaid + soloData.totalPaid).toFixed(1)} {minerID}</h6>
                <h6 id="currpaidzil" style={{ color: "#00c4ff" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="container" id="content" style={{ paddingTop: 8, paddingBottom: 8 }}>
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col" style={{ textAlign: "right", minWidth: "60%" }}>
            <h4>
              <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="white" viewBox="0 0 576 512">
                        <path d="M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3 0 0c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z" />
                    </svg>{" "}
              Payment History
            </h4>
          </div>
          <div className="col flex-nowrap" style={{ textAlign: "right", width: "8%", minWidth: "24px" }}>
              <PaymentSettings />
          </div>
        </div>
        <div className="card-body"><Chart type='bar' options={optionsPayments} data={minerpaychart} style={{ width: "100%" }}/></div>
      </div>
    </div>
  </div>
  <div className="container">
    <div className="card g-2" style={{ marginBottom: 32 }}>
      <div className="table-responsive">
        <table className="table" style={{ backgroundColor: "transparent", color: "#FFFFFF" }}>
              <thead>
                <tr key={minerID}>
                  <th>Worker Name</th>
                  <th>Hashrate</th>
                  <th>Shares Per Second</th>
                  <th>Pool Mode</th>
                  <th>Last Seen</th>
                </tr>
              </thead>
          <tbody id="tablebody">{row}</tbody>
        </table>
      </div>
    </div>
  </div>
  {/* Page END */}
</>

  );
};

export default Miner;
