// AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import './index.css';
import Home from './components/Home';
import Miner from './components/Miner';
import Pool from './components/Pool';
import Network from './components/Network';
import GettingStarted from './components/GettingStarted';
import Navigation from './components/Navbar';

const AppRouter = () => {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/getting-started" element={<GettingStarted />} />
        <Route path="/miner/:searchQuery" element={<Miner />} />
        <Route path="/pool/:poolID" element={<Pool />} />
        <Route path="/network/:poolID" element={<Network />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
