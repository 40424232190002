export function refreshPage() {
    setTimeout(()=>{
        window.location.reload(false);
    }, 250);
    console.log('Reloading. . .')
}
// hashrate conversion
export function HPS(hashpersecond) {
    let units = 'H/s';
    if (hashpersecond > 1000) {
        hashpersecond /= 1000;
        units = 'kH/s';
    }
    if (hashpersecond > 1000) {
        hashpersecond /= 1000;
        units = 'MH/s';
    }
    if (hashpersecond > 1000) {
        hashpersecond /= 1000;
        units = 'GH/s';
    }
    if (hashpersecond > 1000) {
        hashpersecond /= 1000;
        units = 'TH/s';
    }
    return hashpersecond.toFixed(2) + ' ' + units;
}
// profitability calculator
export function CALC(hashrate, timeHours, diff, fee) {
    let blockReward = (2500.2 * 1) - fee;
    let estearnings = (hashrate * blockReward * (60 * 60 * timeHours)) / (diff * (2**32));
    return estearnings;
}

let currenttime = Date.now();

export function TIMEAGO(isodate) {
        let date = new Date(isodate.valueOf());
        date = currenttime - date;
        let units = 'Minutes';
        if (date < 120000) {
            date = 1;
            units = 'Minute';
        }
        if (date >= 120000 && date < 7200000) {
            date /= 60000;
            units = 'Minutes';
        }
        if (date >= 7200000) {
            date /= 3600000;
            units = 'Hours';
        }
        return date.toFixed(0) + ' ' + units + ' ' + 'Ago';
    }

export function timetotime(unixtimestamp) {
					let date = new Date(unixtimestamp);
					let hours = date.getHours();
					let minutes = "0" + date.getMinutes();
					//let seconds = "0" + date.getSeconds();
					let formattedTime = hours + ':' + minutes.substr(-2);
					return formattedTime
				}

export function timetodate(unixtimestamp) {
					let date = new Date(unixtimestamp);
					let hours = date.getUTCHours();
					let minutes = "0" + date.getUTCMinutes();
					//let seconds = "0" + date.getUTCSeconds();
					let formattedTime = hours + ':' + minutes.substr(-2) + ' (UTC)';
					return formattedTime
				}

export function timetodatestring(inputtime) {
					let date = new Date(inputtime);
					let datestring = date.toUTCString();
					datestring = datestring.substring(5, datestring.length-18) + datestring.substring(16, datestring.length-7) + ' (UTC)';
					return datestring
				}

export function roundedtime(input) {
					var today = new Date(input.valueOf());
					var yesterday = new Date(today - (24*60*60*1000));

                    var coeff = 1000 * 60 * 10;
					let convertedtime = new Date(Math.floor(yesterday / coeff) * coeff);

					let newdate = convertedtime.valueOf();
                    return newdate
				}

export function timeago(isodate) {
					let date = new Date(isodate.valueOf());
					date = currenttime - date;
					let units = 'min';
                    if (date < 7200000) {
                        date /= 60000;
                        units = 'min';
                    }
                    if (date >= 7200000) {
                        date /= 3600000;
                        units = 'hrs';
                    }
                    return date.toFixed(0) + units + ' ' + 'ago';
				}

export function datetotime(ISOdate) {
    let input = new Date(ISOdate);
    let date = input.valueOf();
    let days = date.getDay();
    let hours = date.getHours();
    let minutes = "0" + date.getMinutes();
    //let seconds = "0" + date.getSeconds();
    let formattedTime = days + ' ' + hours + ':' + minutes.substr(-2);
    return formattedTime
}

function starttime() {
    var startdate = new Date(currenttime());
    var newday = (Math.floor((((startdate / 600000) * 600000) - 86400000)));
    console.log(newday);
    console.log(roundedtime(newday));
    return newday
}