// Pool.js
import { fetchData } from './api';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, LineController, BarController, Filler } from 'chart.js';
import { Line, Bar, Chart } from "react-chartjs-2";
import { timetodate, timetodatestring, roundedtime, TIMEAGO, HPS, CALC, refreshPage } from './main';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Filler
);
export const optionsHashrate = {
  scales: {
						A: {
							type: 'linear',
							position: 'left',
							min: 0, // Set the minimum value of the y-axis to 0
							grid: {
								color: "rgba(255, 255, 255, 0.5)",
								display: false,
							},
						},
						xAxes: {
							ticks: {
								display: false, //this will remove only the label
							},
                        },
					},
					plugins: {
						title: {
								display: false,
							},
						legend: {
							display: true,
							position: 'bottom',
							align: 'center',
							title: {
								display: false,
							},
						},
					},
				}
export const optionsBlocks = {
  scales: {
						A: {
							type: 'linear',
							position: 'left',
							min: 0, // Set the minimum value of the y-axis to 0
							grid: {
								color: "rgba(255, 255, 255, 0.5)",
								display: true,
							},
						},
                        B: {
							type: 'linear',
							position: 'right',
							min: 0, // Set the minimum value of the y-axis to 0
							grid: {
								color: "rgba(255, 255, 255, 0.5)",
								display: false,
							},
						},
						xAxes: {
							ticks: {
								display: false, //this will remove only the label
							},
                        },
					},
					plugins: {
						title: {
								display: false,
							},
						legend: {
							display: true,
							position: 'bottom',
							align: 'center',
							title: {
								display: false,
							},
						},
					},
				}

let block
let blocktime
let miner
let area
let pool
let server
let luck
let difficulty
let row
const Pool = () => {

  const baseurl = `https://api.evrpool.com/EVR/pools/`;
  const { poolID } = useParams();
  const [poolData, setData1] = useState('');
  const [soloData, setData2] = useState('');
  const [poolInfo, setData3] = useState('');
  const [soloInfo, setData4] = useState('');
  const [poolBlocks, setData5] = useState('');
  const [soloBlocks, setData6] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAndProcessData = async () => {
      try {
        const fetchedData1 = await fetchData(`https://api.evrpool.com/EVR/pools/${poolID}`);
        setData1(fetchedData1);
        const fetchedData2 = await fetchData(`https://api.evrpool.com/EVR/pools/${poolID}SOLO`);
        setData2(fetchedData2);
        const fetchedData3 = await fetchData(`https://api.evrpool.com/EVR/pools/${poolID}/performance`);
        setData3(fetchedData3);
        const fetchedData4 = await fetchData(`https://api.evrpool.com/EVR/pools/${poolID}SOLO/performance`);
        setData4(fetchedData4);
        const fetchedData5 = await fetchData(`https://api.evrpool.com/EVR/pools/${poolID}/blocks`);
        setData5(fetchedData5);
        const fetchedData6 = await fetchData(`https://api.evrpool.com/EVR/pools/${poolID}SOLO/blocks`);
        setData6(fetchedData6);

        setIsLoading(false); // Data has been received, set isLoading to false
      } catch (error) {
        console.log('Error:', error);
        // Handle the error appropriately (e.g., show an error message)
        setIsLoading(false); // Error occurred, set isLoading to false
      }
    };
    fetchAndProcessData();
  }, [poolID]);
  if (isLoading) {
    return <p>Loading...</p>; // Render a loading indicator while data is being fetched
  }

  let symbol = new URL(window.location.href);
  symbol = symbol.pathname;
  symbol = symbol.substr(6, symbol.length)

			// TTF
			let netdiff = ((poolData.pool.networkStats.networkDifficulty + soloData.pool.networkStats.networkDifficulty)/2);
            let totalhash = (poolData.pool.poolStats.poolHashrate + soloData.pool.poolStats.poolHashrate);
            let TTF = (netdiff * 2**32) / totalhash / 60; //in minutes
			// Estimated Earnings
		let estearnings = CALC(totalhash, 24, netdiff, 0.01);
        //Pool Hashrate Chart
        const poolhash = new Array();
		const solohash = new Array();
		const time = new Array();
		let currenttime = Date.now();
		for (let i = 0; i < 25; i++) {
			time.push(timetodate(currenttime));
			if (poolInfo.stats[i] !== undefined) {
				poolhash.push((poolInfo.stats[i].poolHashrate) / 1000000000);
			}
			if (soloInfo.stats[i] !== undefined) {
				solohash.push((soloInfo.stats[i].poolHashrate) / 1000000000);
			}
			if (soloInfo.stats[i] === undefined) {
				solohash.unshift(0);
			}
			if (poolInfo.stats[i] === undefined) {
				poolhash.unshift(0);
			}
			currenttime -= 3600000
		}
            const poolHashrate = {
              labels: time.reverse(),
              datasets: [
                        {
                        label: 'SOLO Hashrate',
							yAxisID: 'A',
							fill: true,
                            borderColor: "rgba(93, 47, 134, 1)",
							backgroundColor: "rgba(93, 47, 134, 0.5)",
                        data: solohash,
						lineTension: 0.4
                    	},
						{
                        label: 'PPLNS Hashrate',
							yAxisID: 'A',
							fill: true,
                            borderColor: "rgba(211, 46, 157, 1)",
							backgroundColor: "rgba(211, 46, 157, 0.5)",
                        data: poolhash,
						lineTension: 0.4
                    	}
					]
            };
            //Pool Blocks Chart&Table
            let recentblocks = new Array();
			recentblocks = poolBlocks.concat(soloBlocks);
            recentblocks.sort(function (a, b) {return b.blockHeight - a.blockHeight});
            const xval2 = new Array();
            const ydiff = new Array();
            const good = new Array();
            const okay = new Array();
            const poor = new Array();
            const bad = new Array();
            const row = [];
            for (let i = 0; i < recentblocks.length; i++) {
                xval2.push(recentblocks[i].blockHeight);
				ydiff.push(recentblocks[i].networkDifficulty / 1000);
                let minernav = '/miner/' + recentblocks[i].miner
            	block = <td>{recentblocks[i].blockHeight}</td>;
				blocktime = <td>{timetodatestring(recentblocks[i].created)}</td>;
				miner = <td><LinkContainer to={minernav}><a className="link-primary" onClick={ refreshPage }>{recentblocks[i].miner}</a></LinkContainer></td>;
                luck = luck = <td style={{color: '#14d60a'}}>{'< 1%'}</td>;
                difficulty = <td>{((recentblocks[i].networkDifficulty / 1000).toFixed(2))}k</td>;
				if (recentblocks[i].source === 'US') {
					area = '🇺🇸';
				}
				if (recentblocks[i].source === 'EU') {
					area = '🇩🇪';
				}
				if (recentblocks[i].source === 'SG') {
					area = '🇸🇬';
				}
				if (recentblocks[i].poolId !== poolID) {
					server =<td style={{color: "#5d2f86"}}>{area} [SOLO]</td>;
				}
				if (recentblocks[i].poolId === poolID) {
					server = <td style={{color: "#d32e9d"}}>{area} [POOL]</td>;
				}
				if (recentblocks[i].effort === undefined) {
						good.push(0);
						okay.push(null);
						poor.push(null);
						bad.push(null);
					}
				if (recentblocks[i].effort <= 1) {
				        luck = <td style={{color: '#14d60a'}}>{((recentblocks[i].effort)*100).toFixed(1)}%</td>;
						good.push(((recentblocks[i].effort)*100).toFixed(1));
						okay.push(null);
						poor.push(null);
						bad.push(null);
					}
				if (recentblocks[i].effort > 1 && recentblocks[i].effort < 2) {
						luck = <td style={{color: '#d0d61c'}}>{((recentblocks[i].effort)*100).toFixed(1)}%</td>;
						good.push(null);
						okay.push(((recentblocks[i].effort)*100).toFixed(1));
						poor.push(null);
						bad.push(null);
					}
				if (recentblocks[i].effort > 2 && recentblocks[i].effort < 3) {
                        luck = <td style={{color: '#d68428'}}>{((recentblocks[i].effort)*100).toFixed(1)}%</td>;
						good.push(null);
						okay.push(null);
						poor.push(((recentblocks[i].effort)*100).toFixed(1));
						bad.push(null);
					}
				if (recentblocks[i].effort > 3) {
				        luck = <td style={{color: '#d61c10'}}>{((recentblocks[i].effort)*100).toFixed(1)}%</td>;
						good.push(null);
						okay.push(null);
						poor.push(null);
						bad.push(((recentblocks[i].effort)*100).toFixed(1));
					}
			row.push(<tr key={i}>{block}{blocktime}{miner}{luck}{difficulty}{server}</tr>);
            }

            const blocksLuck = {
                	labels: xval2.reverse(),
                    datasets:
					[
                    	{
                    	barPercentage: 1,
						categoryPercentage: 1,
                    	type: 'bar',
                        label: 'Luck <100%',
							yAxisID: 'A',
                        borderColor: "rgba(102, 220, 160, 0.75)",
						backgroundColor: "rgba(102, 220, 160, 0.75)",
							spanGaps: false,
                        data: good.reverse()
                    	},
                    	{
                    	barPercentage: 1,
						categoryPercentage: 1,
                    	type: 'bar',
                        label: '100-200% Luck',
							yAxisID: 'A',
                        borderColor: "rgba(255, 222, 157, 1)",
							backgroundColor: "rgba(255, 222, 157, 1)",
							spanGaps: false,
                        data: okay.reverse()
                    	},
						{
						barThickness: 'flex',
						barPercentage: 1,
						categoryPercentage: 1,
						type: 'bar',
                        label: '200-300% Luck',
						yAxisID: 'A',
                        borderColor: "rgba(444, 111, 111, 1)",
						backgroundColor: "rgba(444, 111, 111, 1)",
						spanGaps: false,
                        data: poor.reverse()
                    	},
						{
						barThickness: 'flex',
						type: 'bar',
                        label: 'Luck >300%',
						yAxisID: 'A',
                        borderColor: "rgba(333, 12, 33, 1)",
						backgroundColor: "rgba(333, 12, 33, 1)",
						spanGaps: false,
                        data: bad.reverse()
                    	},
						{
						type: 'line',
						fill: false,
                        label: 'Difficulty',
							yAxisID: 'B',
                        backgroundColor: "rgba(0, 196, 255, 0.5)",
						borderColor: "rgba(0, 196, 255, 0.5)",
                        data: ydiff.reverse(),
						lineTension: 0.4
                    	}
                    ],
                };


  return (
    <>
  <div className="container-fluid">
    {/* Container-1 */}
    <div className="container">
      <div className="card-group">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col flex-nowrap" style={{ color: "#FFFFFF", textAlign: "right", minWidth: "56%", fontSize: "20px" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ height: 32, width: 32, fill: "white" }}>
                      <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/>
                  </svg>
                  {' Pool Hashrate:'}
              </div>
              <div className="col flex-nowrap" style={{ color: "#00c4ff", textAlign: "left", fontSize: "20px" }}>
                    {HPS(poolData.pool.poolStats.poolHashrate + soloData.pool.poolStats.poolHashrate)}
              </div>
            </div>
            <div className="card-body">
                <Line options={optionsHashrate} data={poolHashrate} style={{ width: "100%" }}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Container-2 */}
    <div className="container">
      <div className="card-group" style={{ marginTop: 16 }}>
        <div className="col-lg">
          <div className="card h-53">
            <div className="card-body">
              <div className="row">
                <div className="col-7" style={{ textAlign: "left" }}>
                  <h6>Miners: </h6>
                </div>
                <div className="col" style={{ textAlign: "right" }}>
                  <h6 id="solominers" style={{ color: "rgba(93, 47, 134, 1)" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="white" viewBox="0 0 448 512" >
                      <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                    </svg>
                    {' ' + soloData.pool.poolStats.connectedMiners}
                  </h6>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "-16px" }}>
                <div className="col-7" style={{ textAlign: "left" }}>
                  <h6 id="currminers" style={{ color: "#00c4ff" }}>{((poolData.pool.poolStats.connectedMiners + soloData.pool.poolStats.connectedMiners) + ' Online')}</h6>
                </div>
                <div className="col" style={{ textAlign: "right" }}>
                  <h6 id="poolminers" style={{color: "rgba(211, 46, 157, 1)", textAlign: "right"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="white" viewBox="0 0 640 512" >
                      <path d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
                    </svg>
                    {' ' + poolData.pool.poolStats.connectedMiners}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="card h-53">
            <div className="card-body">
              <div className="row flex-nowrap">
                <div className="col-4 flex-nowrap" style={{ textAlign: "left" }}>
                  <h6>
                    Paid:
                  </h6>
                </div>
                <div className="col-8 flex-nowrap" style={{ textAlign: "right" }}>
                  <h6 id="totalpaid" className="flex-nowrap" style={{ color: "#00c4ff" }}>
                    {(poolData.pool.totalPaid + soloData.pool.totalPaid).toFixed(1) + ' ' + poolData.pool.coin.symbol}
                  </h6>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "-16px", paddingBottom: "5px", paddingTop: "5px" }}>
                <h6 className="text-muted" id="minpayment">
                    {poolData.pool.paymentProcessing.minimumPayment +  ' ' + poolData.pool.coin.symbol + ' Minimum Payout'}
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg">
          <div className="card h-53">
            <div className="card-body">
              <div className="row">
                <div className="col" style={{ textAlign: "left" }}>
                  <h6>Luck:</h6>
                </div>
                <div className="col" style={{ textAlign: "center" }}>
                  <h6 id="soloeffort" style={{ color: "rgba(93, 47, 134, 1)", textAlign: "right" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: 24, width: 24, fill: "white" }}>
                      <path d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96c0-35.35-28.65-64-64-64zM224 288c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z" />
                    </svg>
                    {' ' + (soloData.pool.poolEffort*100).toFixed(1) + '%'}
                  </h6>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "-16px" }}>
                <div className="col" style={{ textAlign: "left" }}>
                  <h6 id="currluck" style={{ color: "#00c4ff" }}>
                      {(((poolData.pool.poolEffort + soloData.pool.poolEffort)/2)*100).toFixed(2) + '%'}
                  </h6>
                </div>
                <div className="col" style={{ textAlign: "center" }}>
                  <h6 id="pooleffort" style={{color: "rgba(211, 46, 157, 1)", textAlign: "right"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style={{ height: 24, width: 24, fill: "white" }}>
                      <path d="M252.3 11.7c-15.6-15.6-40.9-15.6-56.6 0l-184 184c-15.6 15.6-15.6 40.9 0 56.6l184 184c15.6 15.6 40.9 15.6 56.6 0l184-184c15.6-15.6 15.6-40.9 0-56.6l-184-184zM248 224c0 13.3-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24zM96 248c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24zm128 80c13.3 0 24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24zm128-80c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24zM224 72c13.3 0 24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24zm96 392c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V240c0-26.5-21.5-48-48-48H472.5c13.4 26.9 8.8 60.5-13.6 82.9L320 413.8V464zm160-88c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z" />
                    </svg>
                    {' ' + (poolData.pool.poolEffort*100).toFixed(1) + '%'}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Container-3 */}
      <div className="container">
        <div className="card-group">
          <div className="col-lg-6">
            <div className="card h-40 g-2">
              <div className="card-body">
                <div className="row">
                  <div className="col flex-nowrap" style={{ textAlign: "left", minWidth: "62%", marginLeft: "-10px" }}>
                    <h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="white" className="bi bi-stopwatch-fill" viewBox="0 0 16 16" >
                        <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584.531.531 0 0 0 .013-.012l.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354a.717.717 0 0 0-.012.012A6.973 6.973 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1h-3zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0z" />
                      </svg>
                      {' Time-To-Find:'}
                    </h6>
                  </div>
                  <div className="col" style={{ textAlign: "right", marginRight: "-10px" }}>
                    <h6 id="ttf" style={{ color: "#00c4ff" }}>
                        {TTF.toFixed(0) + ' minute(s)'}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card h-40 g-2">
              <div className="card-body">
                <div className="row flex-nowrap">
                  <div className="col flex-nowrap" style={{ textAlign: "left", minWidth: "62%", marginLeft: "-10px" }}>
                    <h6>
                      <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-calculator-fill" viewBox="0 0 16 16" >
                        <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm2 .5v2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0-.5.5zm0 4v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM4.5 9a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM4 12.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zM7.5 6a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM7 9.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zM10 6.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5zm.5 2.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5h-1z" />
                      </svg>
                      {' Estimated Daily Earnings:'}
                    </h6>
                  </div>
                  <div className="col" style={{ textAlign: "right", marginRight: "-10px" }}>
                    <h6 id="estearnings" style={{ color: "#00c4ff" }}>
                        {estearnings.toFixed(1)} {poolID}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Container-3 */}
      <div className="container">
        <div className="card-group">
          <div className="card" style={{ paddingTop: "-16px" }}>
            <div className="card-body">
              <div className="row">
                <div className="col" style={{ textAlign: "center" }}>
                  <h4><svg xmlns="http://www.w3.org/2000/svg" style={{ height: 32, width: 32, fill: "white" }} viewBox="0 0 576 512">
                      <path d="M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5V204.7c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7V413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3V294.7c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3V92.5c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2V152.6L392 121v89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4V354.8L256 323.2v95.9l-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1V354.8L528 323.2v90.7c0 3.2-1.9 6-4.8 7.3z" />
                    </svg>{" "}Recent Blocks</h4>
                </div>
              </div>
              <div className="card-body"><Chart type='bar' options={optionsBlocks} data={blocksLuck} style={{ width: "100%" }}/></div>
            </div>
          </div>
        </div>
      </div>
      {/* TABLE */}
      <div className="container">
        <div className="card">
          <div className="table-responsive">
            <table className="table" style={{ color: "#FFFFFF", borderColor: "#FFFFFF" }}>
              <thead>
                <tr>
                  <th>Block</th>
                  <th>Time</th>
                  <th>Miner</th>
                  <th>Luck</th>
                  <th>Difficulty</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody id="tableconfirmed">
                {row}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* Table END */}
    </div>
    {/* Page END */}
  </div>
</>

  );
}

export default Pool;
