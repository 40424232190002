// GettingStarted.js
import React from 'react';
import { baseURL, fetchData } from './api';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { LOGO_SELECT, EVR, RVN, ZIL } from './COINS';
import { MINING_SOFTWARE } from './MINERS';

let i

const GettingStarted = () => {

  const [show, setShow] = useState(true);
    const handleClose = () => setShow(true);
    const handleShow = () => setShow(false);

    const [select, setSelect] = useState('Evrmore');
    const handleSelect = (event) => {  
    const SELECTED_COIN = event.target.value;
    poolList.set('COIN', SELECTED_COIN);
    setSelect(SELECTED_COIN);
    }

    const [isZil, setIsZil] = useState(false);
    const handleZil = (event) => {
    if(isZil){
     event.target.classList.remove('checked');
     setIsZil(false);
    }
    else{
     event.target.classList.add('checked');
     setIsZil(true);
    }
    }

    // Update primary wallet address with user input
    const [addr, setAddr] = useState('YOUR_WALLET_ADDRESS');
    const handleWalletUpdate = (event) => {
      poolList.set(event.target.name, event.target.value);
      setAddr(event.target.value);
    }

    // Update secondary wallet address with user input
    const [addr2, setAddr2] = useState('YOUR_ZILLIQA_ADDRESS');
    const handleWalletUpdate2 = (event) => {
      poolList.set(event.target.name, event.target.value);
      setAddr2(event.target.value);
    }

    const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
    setInputs(values => ({...values, [event.target.name]: event.target.value}))
    console.log(`initial inputs: ${inputs}`);
    poolList.set(event.target.name, event.target.value);
    const addressinputs = (JSON.parse(JSON.stringify(inputs)));
    for (const [key, value] of Object.entries(addressinputs)) {
      setInputs(event.target.name, event.target.value);
    }
    // console.log(poolList);
    // console.log(addressinputs);
    // console.log(`inputs: ${inputs}`);
    }


    //onClick Server/Location selection
    const [location, setLocation] = useState('us');
    const handleLocation = (event) => { 
    const POOL_LOCATION = event.target.value;
    poolList.set('LOCATION', POOL_LOCATION);
    
    if(POOL_LOCATION === setLocation){
     event.target.classList.remove('active');
     setLocation(POOL_LOCATION);
    }
    else{
     event.target.classList.add('active');
    }
    setLocation(event.target.value);
    }
    //onClick Server/Location selection END


    //onClick Port Selection
    const [port, setPort] = useState('PPLNS');
    const handlePort = (event) => {
    const POOL_PORT = event.target.value;
    poolList.set('PORT', POOL_PORT);
    setPort(event.target.value);
    event.target.classList.add('active');
    }
    //onClick Port Selection END

    //call API for data
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
    const fetchAndProcessData = async () => {
      try {
      const fetchedData = await fetchData(baseURL);
      setData(fetchedData);
      setIsLoading(false); // Data has been received, set isLoading to false
      }
      catch (error) {
      console.log('Error:', error);
      setIsLoading(false); // Error occurred, set isLoading to false
      }
    };
    fetchAndProcessData();
    }, [baseURL]);
    if (isLoading) {
    return <p>Loading...</p>; // Render a loading indicator while data is being fetched
    }
    //get pool data from API
  const coinSelect = new Set();
  const poolList = new Map();
  const coinDropdown = [];
  const poolInfo = new Map();
  const addressInput = [];
  for (i = 0; i < data.pools.length; i++) {
    let name = data.pools[i].coin.name;
    coinSelect.add(name);
    poolList.set(name + 'Symbol', data.pools[i].coin.symbol);
    poolList.set(name + 'Algorithm', data.pools[i].coin.algorithm);
    poolList.set(name + 'Address', data.pools[i].address);
    poolList.set(name + data.pools[i].paymentProcessing.payoutScheme, Object.keys(data.pools[i].ports)[0]);
  }
  //parse pool map for individual coin values
  for (const value of coinSelect) {
      const name = value;
      const symbol = poolList.get(value + 'Symbol');

        coinDropdown.push(
            <option value={name} name={symbol} onChange={handleChange}>
                {name} ({symbol})
            </option>
        )
    }

    const NAME = select + 'Address';
    const VALUE = inputs.NAME;
    const SYMBOL = poolList.get(select + 'Symbol');
    const ALGORITHM = poolList.get(select + 'Algorithm');
    const ADDRESS = poolList.get(select + 'Address');
    const ZIL_ADDRESS = poolList.get('ZilliqaAddress');
    const PORT = poolList.get(select + port);
    // const SOLO = poolList.get(select + 'SOLO');
    addressInput.push(
      <div className="card-body">
            <label className="form-label">
                <h3>
                    {LOGO_SELECT(SYMBOL)}{" "}{select}
                </h3>
            </label>
            <input
                type="text"
                className="input-lg rounded pill"
                style={{textAlign: "center"}}
                placeholder='Enter your wallet address'
                name={NAME}
                value={VALUE}
                onChange={handleWalletUpdate}
            />
        </div>
    )
  //console.log(ADDRESS);

  
    let NANOMINER = 'nanominer.exe -coin ' + SYMBOL + ' -wallet ' + addr + ' -pool1 ' + location + '.evrpool.org:' + PORT;
    let NANOZIL = '-algo zil -wallet ' + addr2 + ' -pool1 us1-zil.shardpool.io:3333' + ' -pool2 eu1-zil.shardpool.io:3333';


  return (
    <>
  {/* Container-1 */}
  <div className="container bloc-md" style={{ maxWidth: 700, marginTop: 64, marginBottom: 64 }}>
    <div className="card" style={{ borderColor: "transparent", background: "transparent" }}>
    <div className="row">
      <div className="col-txt-12">
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-1-square" viewBox="0 0 16 16">
            <path d="M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383h1.312Z" />
            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
          </svg>{" "}
          Select the coin(s) you want to mine
        </h2>
      </div>
    </div>
    <div className="row">
      <div className="col" style={{ textAlign: "center" }}>

        <form style={{ margin: 32 }}>
          <fieldset>
            <select
              name="SELECTED_COIN"
              value={select}
              onChange={handleSelect}
              className="form-select"
              style={{textAlign: "center", backgroundColor: "#07031C", color: "#FFFFFF"}}
            >
              {coinDropdown}
            </select>
          </fieldset>
        </form>

      </div>
    </div>

    <div className="row">
      <div className="col-5">
        <h3>
        <code hidden={isZil}>Disabled</code>
        <code hidden={!isZil} style={{ color: "green" }}>Enabled</code>
        </h3>
      </div>
      <div className="col-2">
        <h4><Form.Switch onClick={handleZil}/></h4>
      </div>
      <div className="col-5">
        <h3>{ZIL}{" "}Zilliqa</h3>
      </div>
    </div>

      <div className="row">
      <div className="col-txt-12">
        <h6 className="text-muted">
          Dual-mine Zilliqa on the same GPU for up to ~20% more earnings!
        </h6>
      </div>
    </div>
    </div>
  </div>
  {/* Container-1 END */}

  {/* Container-2 */}
  <div className="container bloc-md" style={{ maxWidth: 700, marginTop: 64, marginBottom: 64 }}>
    <div className="card" style={{ borderColor: "transparent", background: "transparent" }}>
      <div className="card-header">
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-2-square" viewBox="0 0 16 16"><path d="M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306Z" /><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
          </svg>{" "}Get your wallet address
        </h2>
      </div>

      {addressInput}

      <div className="card-body" hidden={!isZil}>
        <label className="form-label">
          <h3>{ZIL}{" "}Zilliqa</h3>
        </label>
        <input
          name="ZilliqaAddress"
          value={inputs.ZilliqaAddress}
          type="text"
          className="input-lg rounded pill"
          style={{ textAlign: "center" }}
          placeholder="Enter your Zilliqa address"
          onChange={handleWalletUpdate2}
        />
      </div>
    </div>
  </div>
    {/* Container-2 END */}

    {/* Container-3 */}
    <div className="container bloc-md" style={{ maxWidth: 700, marginTop: 64, marginBottom: 64 }}>
      <div className="card-header">
        <div className="col-txt-12">
          <h2><svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-3-square" viewBox="0 0 16 16"><path d="M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318Z" /><path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
          </svg>
            {" "}Choose a pool location
          </h2>
        </div>
      </div>
      <div className="row">

      <div className="card-body">

        <div className="btn-group-vertical" role="group" style={{ width: "80%", maxWidth: "464", margin: "16px" }} data-toggle="buttons" value={location}>
          <input type="radio" className="btn-check" name="US" id="US" value='us' onClick={handleLocation}/>
            <label className="btn btn-md" htmlFor="US" name="US" id="US" value='us'>
              🇺🇸 North America
            </label>
          <input type="radio" className="btn-check" name="EU" id="EU" value='eu' onClick={handleLocation}/>
            <label className="btn btn-md" htmlFor="EU" name="EU" id="EU">
              🇩🇪 Europe
            </label>
          <input type="radio" className="btn-check" name="AP" id="AP" value='ap' onClick={handleLocation}/>
            <label className="btn btn-md" htmlFor="AP" name="AP" id="AP" value='ap'>
              🇸🇬 Asia / Pacific
            </label>
        </div>


              <div className="btn-group" style={{ width: "80%", maxWidth: "464", margin: "16px" }} data-toggle="buttons">
                <input type="radio" className="btn-check" name="options" id="pool" value='PPLNS' onClick={handlePort}/>
                  <label className="btn btn-start-primary" htmlFor="pool">
                    <h5><svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="currentColor" viewBox="0 0 640 512"><path d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z"/>
                      </svg>{" "}Pool
                    </h5>
                  </label>
                <input type="radio" className="btn-check" name="options" id="Solo" value='SOLO' onClick={handlePort}/>
                  <label className="btn btn-start-secondary" htmlFor="Solo">
                    <h5><svg xmlns="http://www.w3.org/2000/svg" width={27} height={27} fill="currentColor" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/>
                      </svg>{" "}Solo
                    </h5>
                  </label>
              </div>

            </div>
        </div>
    </div>
    {/* Container-3 END */}

    <div className="container bloc-md" style={{ marginTop: 64, marginBottom: 64 }}>
      <div className="row">
        <div className="col-txt-12">
          <h2>
            <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-4-square" viewBox="0 0 16 16">
              <path d="M7.519 5.057c.22-.352.439-.703.657-1.055h1.933v5.332h1.008v1.107H10.11V12H8.85v-1.559H4.978V9.322c.77-1.427 1.656-2.847 2.542-4.265ZM6.225 9.281v.053H8.85V5.063h-.065c-.867 1.33-1.787 2.806-2.56 4.218Z" />
              <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2Z" />
            </svg>{" "}
            Configure your mining software
          </h2>
        </div>
      </div>
      <div className="row">

        <div className="card" style={{ marginTop: 32, marginBottom: 32 }}>
          <div className="card-body">
            <div className="row">
              <div className="col-10" style={{ textAlign: "left" }}>
                <h3>
                  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="white" style={{ margin: 4 }} className="bi bi-github" viewBox="0 0 16 16">\n' +
                    <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                  </svg>
                  <a href="https://github.com/nanopool/nanominer/releases/tag/v3.9.0" className="link-primary">
                    nanominer
                  </a>
                </h3>
              </div>
              <div className="col" style={{ textAlign: "right" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="green" style={{ margin: 4 }} className="bi bi-nvidia" viewBox="0 0 16 16">
                  <path d="M1.635 7.146S3.08 5.012 5.97 4.791v-.774C2.77 4.273 0 6.983 0 6.983s1.57 4.536 5.97 4.952v-.824c-3.23-.406-4.335-3.965-4.335-3.965ZM5.97 9.475v.753c-2.44-.435-3.118-2.972-3.118-2.972S4.023 5.958 5.97 5.747v.828h-.004c-1.021-.123-1.82.83-1.82.83s.448 1.607 1.824 2.07ZM6 2l-.03 2.017A6.64 6.64 0 0 1 6.252 4c3.637-.123 6.007 2.983 6.007 2.983s-2.722 3.31-5.557 3.31c-.26 0-.504-.024-.732-.065v.883c.195.025.398.04.61.04 2.638 0 4.546-1.348 6.394-2.943.307.246 1.561.842 1.819 1.104-1.757 1.47-5.852 2.657-8.173 2.657a6.84 6.84 0 0 1-.65-.034V14H16l.03-12H6Zm-.03 3.747v-.956a6.4 6.4 0 0 1 .282-.015c2.616-.082 4.332 2.248 4.332 2.248S8.73 9.598 6.743 9.598c-.286 0-.542-.046-.773-.123v-2.9c1.018.123 1.223.572 1.835 1.593L9.167 7.02s-.994-1.304-2.67-1.304a4.9 4.9 0 0 0-.527.031Z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="red" style={{ margin: 4 }} className="bi bi-amd" viewBox="0 0 16 16">
                  <path d="m.334 0 4.358 4.359h7.15v7.15l4.358 4.358V0H.334ZM.2 9.72l4.487-4.488v6.281h6.28L6.48 16H.2V9.72Z" />
                </svg>
              </div>


              <code className="text-muted">
                {NANOMINER}
              </code>
              <code className="text-muted" id="nanominerZIL" hidden={!isZil}>
                {NANOZIL}
              </code>
            </div>
          </div>
        </div>


      </div>
    </div>

    <div className="container">
      <div className="row">
        <div className="col-txt-12" style={{ paddingTop: 32 }}>
          <h3>
            Trouble connecting? Join our{" "}
            <a
              className="btn btn-lg btn-primary"
              href="https://discord.gg/ADaMmqj9Nr"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
              </svg>{" "}
              Discord
            </a>{" "}
            for live support.
          </h3>
        </div>
        <div className="col-txt-12" style={{ padding: 16 }} />
      </div>
    </div>
</>

  );
}

export default GettingStarted;