// api.js
import React, { useEffect, useState } from 'react';

export const baseURL = "https://api.evrpool.com/EVR/pools/";

export async function fetchData(url){
try {
  const response = await fetch(url).then(res => res.json());
  return response
}
catch (error) {
    console.log('Error:', error);
    // Handle the error appropriately (e.g., show an error message)
  }
}

const Api = ({ url }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const json = await response.json();
        setData(json);
        setLoading(false);
        console.log('API Output:', json); // Log the API output
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url]);

  if (loading) {
    return console.log("Loading...");
  }

  if (error) {
    return console.log(error.message);
  }

  return data; // Return the API data as a variable
};

export default Api;
