import { useState } from 'react';
import ReactDOM from 'react-dom/client';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { baseURL } from './api';

let minerID

export const PaymentSettings = () => {

  const { searchQuery } = useParams();
  if (searchQuery[0] === 'R' || 'm') {
      minerID = 'RVN';
  }
  if (searchQuery[0] === 'E') {
      minerID = 'EVR';
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let settingsURL = baseURL + minerID + '/miners/' + searchQuery + '/settings';
      var payload = {
        "ipAddress": inputs.ipAddress,
        "settings": {
          "paymentThreshold": inputs.amount
        }
      };

      fetch(settingsURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Include any required authorization headers
        },
        body: JSON.stringify(payload)
      })
        .then(response => response.json())
        .then(data => {
          alert(`Minimum payout is now set to: ${data.paymentThreshold} ${minerID}`);
          console.log(data.paymentThreshold); // Handle the response
        })
        .catch(error => {
          alert(error);
          console.error("Error:", error); // Handle any errors that occurred during the request
        });
    setInputs('');
  }

  return (
    <>
      <Button className="btn btn-settings align-items-center align-text-center justify-content-center" onClick={handleShow}>
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
          <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
        </svg> Settings
      </Button>

      <Modal
          show={show}
          onHide={handleClose}
          style={{ backgroundImage: "linear-gradient", color: "(#160954, #0F0638, #07031C, #020008)" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Settings</Modal.Title>
        </Modal.Header>

        <Form className="d-flex justify-content-center" onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group as={Row} className="mb-3">
              <Form.Label>IP Address:
                <input
                  className="input-md rounded"
                  placeholder="Enter the IP address of your top worker"
                  type="text"
                  name="ipAddress"
                  value={inputs.ipAddress || ""}
                  onChange={handleChange}
                />
              </Form.Label>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
              <Form.Label>Minimum Payout:
                <input
                  className="input-md rounded"
                  placeholder="Enter desired minimum payout"
                  type="text"
                  name="amount"
                  value={inputs.amount || ""}
                  onChange={handleChange}
                />
              </Form.Label>
            </Form.Group>
            <div className="row">
              <div className="col">
                <Button variant="primary" onClick={handleClose}>
                  Close
                </Button>
              </div>
              <div className="col">
                <Button type="submit" variant="primary">
                  Save Changes
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Form>

      </Modal>
    </>
  );
};