export const EVR = <svg version="1.1" style={{ height: 45, width: 45 }} id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 264 264">
                <g>
                    <g>
                        <g>
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="173.5123" y1="180.3027" x2="90.5772" y2="82.8018">
                            <stop offset="0" stopColor="#00C4FF"/>
                            <stop offset="1" stopColor="#6EDEFF"/>
                        </linearGradient>
                        <circle className="st0" fill="url(#SVGID_1_)" cx="132" cy="131.6" r="64"/>
		                </g>
                    <circle className="st1" opacity="0" fill="#29ABE2" cx="132" cy="131.6" r="48"/>
	                </g>
                    <g>
                    <image style={{ opacity: 0.2 }} overflow="visible" width="104" height="115" href="AC21839C.png"
                       transform="matrix(1 0 0 1 87 81)">
                    </image>
                <g>
			    <g>
				<path d="M132,173.1h-27.6c-3.6,0-6.8-2-8.3-5.3s-1-7,1.4-9.7l22.8-26.4c0-0.1,0-0.1,0-0.2L97.5,105c-2.4-2.7-2.9-6.5-1.4-9.7
					c1.5-3.3,4.7-5.3,8.3-5.3H132l8.3,4.4l-8.3,4.6h-27.6c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1l22.8,26.4
					c2.9,3.4,2.9,8.5,0,11.9l-22.8,26.4c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.2c0,0,0,0,0.1,0H132V173.1z"/>
                <path className="st2" fill="#FFFFFF" d="M143.8,131.6c0-0.1,0-0.1,0-0.2l22.8-26.4c2.4-2.7,2.9-6.5,1.4-9.7c-1.5-3.3-4.7-5.3-8.3-5.3h-27.6v0
					l8.2,4.4l-8.2,4.5v0h27.6c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1L137,125.6c-2.9,3.4-2.9,8.5,0,11.9l22.8,26.4
					c0,0,0,0.1,0,0.1c0,0,0,0.2-0.1,0.2c0,0,0,0-0.1,0h-27.6l-0.1,0l-8.3,4.6l8.3,4.3l0.1,0h27.6c3.6,0,6.8-2,8.3-5.3
					c1.5-3.3,1-7-1.4-9.7L143.8,131.6z"/>
			</g>
		</g>
	</g>
</g>
</svg>;

export const RVN = <svg version="1.1" style={{ height: 45, width: 45 }} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1839.3 2823.9">
                    <g>
                        <path fill="#F0533A" d="M1231.2,2179.5c-2.4-4.1-5.2-8-7.1-12.3c-34.5-77.6-69-155.2-103.5-232.8c-48.1-108.2-96.3-216.4-144.4-324.6
                            c-71.7-161.2-143.5-322.5-215.2-483.7c-57.4-129-114.8-258-172.2-387c-2.7-6.1-5.8-12-8.2-18.2c-1.3-3.2-1.6-6.8-2.3-10.2
                            c5.5-2.8,10.4-3.2,15.6-2.4c44,6.3,88.1,12.7,132.1,18.8c39,5.4,78,10.6,117,16c46.6,6.4,93.2,13.1,139.8,19.5
                            c50.7,6.9,101.4,13.6,152,20.5c50.4,6.9,100.8,14,151.2,20.9c39,5.4,78,10.6,117,15.9c6.1,0.8,12.2,1.6,18.2,2.8
                            c7.9,1.5,9.6,3.3,8.7,11.5c-1.5,14.2-3.3,28.4-5.4,42.6c-4.2,29.4-8.7,58.7-13.1,88c-4.9,32.6-9.7,65.3-14.5,97.9
                            c-3.9,26.6-7.7,53.1-11.6,79.7c-4.4,30.9-8.8,61.8-13.3,92.6c-4.3,29.4-8.6,58.7-12.9,88c-4.3,28.8-8.7,57.6-13,86.5
                            c-4.4,29.8-8.8,59.7-13.1,89.6c-4.4,30.1-8.6,60.2-12.9,90.4c-3.8,26.8-7.7,53.7-11.6,80.5c-4.8,33.1-9.7,66.3-14.6,99.4
                            c-4.3,29.3-8.7,58.7-13.1,88c-4.4,29.3-8.7,58.7-13,88c-4.4,29.9-8.8,59.7-13.1,89.6c-4.4,30.1-8.6,60.2-13,90.4
                            c-2.2,15.2-4.7,30.3-7.1,45.5c-0.4,2.8-1.2,5.5-1.8,8.2C1232.4,2179.2,1231.8,2179.4,1231.2,2179.5z"
                        />
                        <path fill="#394283" d="M130.7,2665.5c-2.1-0.1-5.7-0.3-9.3-0.5c-0.3-0.5-0.5-1.1-0.8-1.6c8.2-2.5,8.5-9.2,9.8-15.5
                            c16.3-73.6,32.6-147.3,48.9-220.9c18.6-84.1,37.3-168.2,56-252.3c20.7-93.1,41.7-186.1,62.4-279.2c19.8-89.1,39.4-178.2,59.1-267.4
                            c22.3-100.8,44.6-201.7,67-302.5c20.1-90.8,40.3-181.7,60.5-272.5c18.7-84.1,37.4-168.2,56.1-252.3c4-18,8.2-35.9,12.6-53.8
                            c0.6-2.6,0.1-7.2,4.4-7.1c4.4,0.2,3.9,4.8,4.4,7.8c4.7,27.2,9.3,54.4,13.8,81.7c6,35.8,11.8,71.7,17.8,107.5
                            c6.4,38.9,12.8,77.7,19.3,116.6c7.5,45.2,15,90.3,22.4,135.5c6.9,41.6,13.7,83.3,20.6,124.9c6.9,41.9,13.9,83.7,20.8,125.6
                            c6.4,38.9,12.7,77.7,19.1,116.6c6.5,39.6,12.9,79.2,19.4,118.9c6.3,38.4,12.7,76.7,19.1,115c6.8,40.6,13.7,81.2,20.5,121.8
                            c6.6,39.6,12.9,79.2,19.5,118.9c6.3,37.8,12.7,75.7,19,113.5c1.5,9.1,2.5,18.3,4.6,27.2c2.1,8.6-1,14-7.8,18.8
                            c-20.7,14.6-41.2,29.5-61.7,44.4c-194,139.7-387.9,279.4-581.9,419.2C141.3,2657.1,136.6,2660.9,130.7,2665.5z"
                        />
                        <path fill="#3A4283" d="M567.6,721.3c2,3.5,4.3,6.8,5.9,10.5c37.1,83.4,74.2,166.9,111.2,250.3c60,135.1,120,270.2,180,405.3
                            c94.7,213.2,189.3,426.4,284,639.6c21.7,48.9,43.3,97.7,65,146.6c5,11.3,4,12.5-8.5,12.3c-26.4-0.5-52.7-0.6-79.1-1.2
                            c-32.8-0.7-65.5-2-98.2-2.7c-31.7-0.7-63.5-1-95.2-1.6c-38.1-0.7-76.3-1.6-114.4-2.4c-7.1-0.2-9.3-1.5-10.8-8.4
                            c-2.3-10.2-3.9-20.6-5.6-30.9c-5.9-35.3-11.7-70.7-17.6-106c-6.5-38.6-13-77.2-19.6-115.7c-7.5-44.4-15-88.8-22.4-133.2
                            c-7.9-47.2-15.8-94.3-23.7-141.5c-7.4-44.1-15-88.2-22.4-132.4c-7.4-44.4-14.7-88.8-22.2-133.2c-6.8-40.6-13.9-81.2-20.8-121.8
                            c-6.9-40.8-13.8-81.7-20.7-122.6c-7.3-43.6-14.6-87.3-21.9-130.9c-7.4-43.6-14.9-87.2-22.3-130.8c-7.5-44.1-15-88.3-22.5-132.4
                            c-0.9-5.5-1.1-11-0.3-16.5C566.2,721.7,566.9,721.5,567.6,721.3z"
                        />
                        <path fill="#F79536" d="M600.9,699.5c0.5-5.9,4.7-6.1,7.9-7.2c17.7-5.8,35.5-11.4,53.3-17.1c148.5-47.3,297-94.7,445.6-142
                            c74-23.6,148-47.2,222-70.8c2.7-0.9,5.4-1.7,8.1-2.2c5.5-0.9,7.6,0.2,9.3,5.5c1.7,5.3,3,10.8,4.3,16.3
                            c19.7,82.8,39.4,165.6,59.1,248.4c5.6,23.4,11.1,46.8,16.7,70.1c0.3,1.2,0.7,2.5,0.9,3.7c0.6,5.4-1.6,7.6-7.1,6.9
                            c-7.1-0.8-14.2-1.8-21.3-2.8c-33.2-4.5-66.3-9.1-99.5-13.6c-32.2-4.4-64.3-8.9-96.5-13.2c-28.9-3.9-57.8-7.4-86.7-11.4
                            c-31.4-4.3-62.8-8.9-94.2-13.2c-27.9-3.9-55.7-7.5-83.6-11.3c-28.6-3.9-57.2-7.9-85.9-11.8c-28.6-3.8-57.3-7.5-85.9-11.3
                            c-28.4-3.8-56.7-7.8-85.1-11.7c-24.3-3.3-48.6-6.5-73-9.8C606.7,700.8,604,700.1,600.9,699.5z"
                        />
                        <path fill="#3D4585" d="M1442.9,843.3c2.9,2.4,5.5,3.9,7.3,6.1c36.2,44.2,72.2,88.5,108.4,132.6c7,8.5,7.2,17.3,4.4,27.3
                            c-10.7,37.9-21.2,75.8-31.5,113.8c-34.4,127.1-68.6,254.3-103,381.4c-52.8,195.2-105.6,390.5-158.4,585.7c-3.8,14-7.9,28-11.9,42
                            c-0.6,1.9-1.5,3.8-2.9,7.2c-0.5-2.9-1.1-4.4-1-5.9c0.4-5.3,0.7-10.7,1.5-16c3.1-22,6.3-44,9.5-66.1c4.4-30.4,8.7-60.8,13.1-91.1
                            c4.9-33.4,9.8-66.8,14.7-100.2c5.4-36.7,10.9-73.4,16.3-110.1c4.9-33.2,9.6-66.3,14.5-99.5c4.8-33.4,9.6-66.8,14.5-100.2
                            c4.9-33.4,9.8-66.8,14.7-100.2c4.9-33.7,9.8-67.3,14.7-101c4.3-29.9,8.6-59.7,12.9-89.6c4.3-29.9,8.6-59.7,13-89.6
                            c4.3-29.9,8.8-59.7,13.1-89.6c4.9-33.7,9.9-67.3,14.8-101c4.4-30.1,8.6-60.3,13-90.4c1.9-12.9,4-25.8,6.1-38.6
                            C1441.1,848.3,1441.9,846.4,1442.9,843.3z"
                        />
                        <path fill="#3D4585" d="M1340.5,445.3c-1.3,1.3-2.2,2.6-3.4,3.1c-2.3,1.1-4.8,1.7-7.2,2.5c-140,44.6-280,89.2-420,133.7
                            c-92.5,29.4-185.1,58.7-277.7,88.1c-6.6,2.1-13.1,4.3-19.6,6.5c-2.6,0.9-5.1,1.3-6.5-1.9c-1.3-2.9,0-5.1,2.3-6.6
                            c3.6-2.4,7.5-4.4,11.1-6.8c153-102.5,306-205.1,459.1-307.6c4-2.7,8.2-5.3,12-8.3c7.5-6,15.2-6.1,23.7-2.4
                            c18.5,8.1,37,16.2,55.6,24.1c54,23.2,108.1,46.3,162.1,69.4c1.9,0.8,3.8,1.5,5.5,2.6C1338.5,442.4,1339.3,443.8,1340.5,445.3z"
                        />
                        <path fill="#F79536" d="M1362.5,435.9c-8.7,0.4-11.1-1-12.9-7.3c-6.4-22.4-12.4-44.8-19-67.1c-9.8-33.3-20.1-66.6-30.1-99.8
                            c-1-3.4-2-6.9-2.3-10.4c-0.8-8.7,2.8-12,11.3-9.9c19.1,4.8,38.1,9.7,57,15c94.2,26.3,188.4,52.7,282.6,79.1c3.4,1,7,1.5,10.5,2.4
                            c2,0.5,4,1,5.8,1.9c3.6,1.6,7,4,7.1,8.3c0.1,4-3.2,6.2-6.4,7.9c-2.2,1.2-4.7,2.1-7.1,2.7c-92.9,24.5-185.7,48.9-278.6,73.3
                            C1373.7,433.4,1367,434.8,1362.5,435.9z"
                        />
                        <path fill="#F0543B" d="M1074.2,348.7C912.8,456.2,752.1,563.3,591.4,670.4c-2.1,1.4-4.6,2.2-8,3.7c-0.4-2.4-1.2-4.2-0.9-5.8
                            c0.5-2.2,1.9-4.2,3-6.2c27.2-49.9,54.4-99.7,81.4-149.7c5.1-9.5,12.3-15.9,22.3-19.8c101.8-39.9,203.6-80,305.3-120
                            c19.7-7.8,39.5-15.5,59.2-23.3c3.6-1.4,7.2-2.5,10.9-3.8C1067.8,344.5,1070.6,344.9,1074.2,348.7z"
                        />
                        <path fill="#F0543B" d="M1213.6,370.9c-3.7,3.2-6.6,1.5-9.3,0.4c-15.6-6.4-31.2-12.8-46.7-19.4c-101.4-43.7-202.7-87.6-304-131.4
                            c-3.3-1.4-6.6-2.8-9.7-4.6c-3.1-1.8-3.3-4.6-0.4-6.7c2.5-1.7,5.3-3.1,8.1-4.4c47.6-22.5,95.2-45,142.8-67.4
                            c7.4-3.5,11.4-2.5,17.5,4.3c22.2,24.8,44.4,49.6,66.5,74.5c9,10.1,17.6,20.6,26.7,30.7c4.9,5.5,10.5,10.5,15.7,15.8
                            c2.8,2.9,5.4,6,7.9,9.2c2.7,3.4,4.6,7.6,7.7,10.5c11,10,20.6,21.1,30.1,32.6c13.5,16.4,27.9,32.1,41.8,48.1
                            C1210.2,365.5,1211.8,368.3,1213.6,370.9z"
                        />
                        <path fill="#3E4585" d="M675.3,488.3c0.4-3.5,0.3-5.8,1-7.8c13.1-39,26.2-78.1,39.4-117c5.1-14.9,5.2-15.1,20.8-16.1
                            c13.3-0.9,26.6-1.5,39.9-2c49.6-1.8,99.3-3.4,148.9-5.4c42.2-1.6,84.4-3.6,126.6-5.3c3.2-0.1,6.7-1.2,9.8,2.6
                            c-1.7,1-3.2,2.1-4.9,2.7c-120.4,47.4-240.7,94.8-361.1,142.2c-3.6,1.4-7,3.3-10.6,4.5C682.2,487.5,679.2,487.6,675.3,488.3z"
                        />
                        <path fill="#F0543B" d="M1062.4,323.8c-112.7,6.5-225.5,10-339.1,11.9c0.6-5.5,3-8.3,5.7-11c28.6-29.6,57.3-59.2,85.9-88.8
                            c2.8-2.9,5.7-5.9,8.5-8.9c6.4-6.7,9.2-7.4,17.6-3.8c14.6,6.3,29,12.7,43.5,19.1c53.6,23.4,107.2,46.8,160.9,70.2
                            c4.4,1.9,9,3.7,13.3,5.8c1.6,0.8,2.8,2.5,4.1,3.9C1062.7,322.7,1062.6,323.3,1062.4,323.8z"
                        />
                        <path fill="#F79636" d="M1039.6,148.8c3.8,1.2,7.7,2.1,11.4,3.6c22.7,9.4,45.3,18.9,67.9,28.4c48.3,20.3,96.6,40.7,144.8,61.1
                            c1.2,0.5,2.4,1,3.5,1.5c7.5,3.5,8.9,5.7,6.2,13.9c-7.9,24.3-16.1,48.5-24.3,72.7c-5.1,15-10.2,30-15.2,44.4c-4.2,2.2-6.6,0.4-8.7-2
                            c-16.7-19.4-34-38.3-50-58.3c-13.9-17.4-29.2-33.5-43.7-50.3c-3.2-3.7-6-7.6-9-11.4c-2.6-3.2-5.1-6.4-7.8-9.5
                            c-23.1-26.4-46.2-52.7-69.3-79c-2-2.3-4-4.7-5.6-7.3c-1.1-1.8-1.5-3.9-2.2-5.9C1038.3,150.1,1039,149.5,1039.6,148.8z"
                        />
                        <path fill="#F0553D" d="M1019.8,129.3c2.5-0.1,4.7-0.4,6.9-0.4c52.4-0.2,104.9-0.2,157.3-0.6c6.1,0,10.1,3.2,13.8,7
                            c8.4,8.6,16.6,17.4,24.9,26.1c19.4,20.4,38.7,40.8,58,61.3c1.8,1.9,3.4,3.8,4.9,5.9c1.7,2.2,2.6,5,0.5,6.9
                            c-1.4,1.2-4.3,1.7-6.2,1.2c-5.4-1.5-10.7-3.5-15.9-5.6c-79.2-31.7-158.3-63.4-237.4-95.2C1023.8,134.8,1020.2,134.2,1019.8,129.3z"
                        />
                        <path fill="#F0553C" d="M1287.6,257c0.8,2.3,1.8,4.6,2.5,7c15.6,51.9,31.2,103.7,46.7,155.6c0.3,1,0.8,1.9,0.9,2.9
                            c0.1,2.1-0.2,4.3-0.3,6.4c-2.2-0.3-4.6,0-6.5-0.9c-15.5-7.3-30.9-14.7-46.3-22.2c-12-5.8-23.9-11.6-35.8-17.6
                            c-7.3-3.7-8.2-5.7-5.6-13.4c12.9-37.7,25.9-75.4,38.9-113.1c0.7-1.9,2.1-3.6,3.1-5.4C1286.1,256.6,1286.9,256.8,1287.6,257z"
                        />
                    </g>
                    </svg>;

export const ZIL = <svg clipRule="evenodd" style={{ height: 45, width: 45 }} fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit={2} viewBox="0 0 560 400" xmlns="http://www.w3.org/2000/svg">
              <g fillRule="nonzero">
                <path d="m672.926 1657.83-359.964 160.49-954.935 486.06 954.935 459.13v-758.9l359.964-162.22v1121.16l-359.964 160.5-1406.032-672.93v-357.67l973.273-477.47-973.273-467.72v-362.831l365.691-160.493 1400.305 674.074z" fill="#49c1bf" transform="matrix(.11325 0 0 .11325 303.790648 -12.303809)"/>
                <path d="m-1093.07 785.429 1406.032 674.071 359.964-160.49-1400.305-674.074z" fill="#077a8f" transform="matrix(.11325 0 0 .11325 303.790648 -12.303809)"/>
                <path d="m209.788 1408.49 359.963-160.5v358.82l-359.963 160.49z" fill="#298e97" transform="matrix(.11325 0 0 .11325 315.476915 -6.6985)"/>
                <path d="m-1093.07 773.965v362.835l973.273 467.72-973.273 477.47v357.67l1406.032 672.92v-359.96l-954.935-459.7 954.935-486.06v-358.82z" fill="#49c1bf" transform="matrix(.11325 0 0 .11325 303.790648 -10.969157)"/>
                <path d="m209.788 3032.34 359.963-160.5v-1121.16l-359.963 162.21z" fill="#298e97" transform="matrix(.11325 0 0 .11325 315.476915 -2.184355)"/>
              </g>
            </svg>;

export function LOGO_SELECT(logoID) {
    if (logoID === 'EVR') {
        logoID = EVR;
    }
    if (logoID === 'RVN') {
        logoID = RVN;
    }
    return logoID;
}