// Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavbarToggle from 'react-bootstrap/NavbarToggle';
import NavbarCollapse from 'react-bootstrap/NavbarCollapse';
import Dropdown from 'react-bootstrap/Dropdown';
import logo from './logo.svg';
import { baseURL, fetchData } from './api';
import { refreshPage } from './main';

let set
let i

const Navigation = () => {
    // Miner Lookup
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/miner/${searchQuery}`);
    setSearchQuery('');
  };
    // Api
	const [data, setData] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	  useEffect(() => {
		const fetchAndProcessData = async () => {
		  try {
			const fetchedData = await fetchData(baseURL);
			setData(fetchedData);
			setIsLoading(false); // Data has been received, set isLoading to false
		  }
		  catch (error) {
			console.log('Error:', error);
			setIsLoading(false); // Error occurred, set isLoading to false
		  }
		};
		fetchAndProcessData();
	  }, [baseURL]);
	  if (isLoading) {
		return console.log('Loading. . .');; // Render a loading indicator while data is being fetched
	  }
	      // Pool Navigation
          const set = new Map();
          const pool = [];
          const network = [];
          for (i = 0; i < data.pools.length; i++) {
              set.set(data.pools[i].coin.name, data.pools[i].coin.symbol);
          }
          set.forEach((value, key) => {
             let poolnav = '/pool/' + value;
             let networknav = '/network/' + value;
                pool.push(<LinkContainer to={poolnav} key={key}>
                        <Dropdown.Item key={key} onClick={ refreshPage }>{key}</Dropdown.Item >
                        </LinkContainer>);
                network.push(<LinkContainer to={networknav} key={key}>
                        <Dropdown.Item key={key} onClick={ refreshPage }>{key}</Dropdown.Item>
                        </LinkContainer>);
          });

  return (
    <Navbar expand="lg" className="navbar navbar-fixed-top sticky-top navbar-light border-bottom">
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="rounded-4"/>


            <LinkContainer to="/" className="navbar-brand img justify-content-center col-sm-1">
              <Navbar.Brand className="navbar-brand img justify-content-center" style={{ width: "100%" }} onClick={ refreshPage }>
                <img src={logo} width="256px" height="84px" alt="EVRpool_Logo" />
              </Navbar.Brand>
            </LinkContainer>

        <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className="navbar-nav nav-fill col-sm-3 mx-5 px-4">
            <LinkContainer to="/getting-started">
              <Nav.Link>
                <Button variant="primary" className="d-inline-flex align-items-center justify-content-center" style={{ width: "100%", height: 79 }} onClick={ refreshPage }>
                  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="white" className="bi bi-tools" viewBox="0 0 16 16">
                    <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                    </svg>
                    &nbsp;Getting Started
                </Button>
              </Nav.Link>
            </LinkContainer>
          </Nav>

        <div className="col-sm-4">
            <Form className="d-flex flex-column justify-content-center" onSubmit={handleSearch}>
              <div className="d-flex">
                <input className="input-lg rounded-pill" type="text"
                       value={searchQuery}
                       onChange={(e) => setSearchQuery(e.target.value)}
                       style={{ textAlign: "center" }} required="true"
                       placeholder="Search Miner by Address"/>
                <button className="btn-info-outline rounded-circle" onClick={ refreshPage }>
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" fill="currentColor"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" />
                    </svg>
                </button>
              </div>
            </Form>
        </div>

        <div className="col-sm-4">
          <Nav.Link className="nav nav-pills d-flex flex-wrap">
            <Dropdown style={{ width: "50%" }} className="d-flex flex-wrap">
              <Dropdown.Toggle variant="info" className="btn btn-info dropdown-toggle d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                &nbsp;Pool Statistics
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                {pool}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown style={{ width: "50%" }} className="d-flex flex-wrap">
              <Dropdown.Toggle variant="info" className="btn btn-info dropdown-toggle d-flex align-items-center justify-content-center" style={{ width: "100%" }}>
                &nbsp;Network Information
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: "100%" }}>
                {network}
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Link>
        </div>

        </Navbar.Collapse>

        <a href="https://discord.gg/ADaMmqj9Nr">
          <Button className="btn-help d-inline-flex align-items-center justify-content-center">
            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
            </svg>
          </Button>
        </a>

      </Container>
    </Navbar>
  );
};

export default Navigation;
