// Network.js
import { fetchData } from './api';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from "react-chartjs-2";
import { timetodate, roundedtime, TIMEAGO, HPS, CALC, refreshPage } from './main';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
};

const Network = () => {

    const baseurl = `https://api.evrpool.com/EVR/pools/`;
    const { poolID } = useParams();
    const [data, setData] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	  useEffect(() => {
		const fetchAndProcessData = async () => {
		  try {
			const fetchedData = await fetchData(`https://api.evrpool.com/EVR/pools/${poolID}/performance`);
			setData(fetchedData);
			setIsLoading(false); // Data has been received, set isLoading to false
		  }
		  catch (error) {
			console.log('Error:', error);
			setIsLoading(false); // Error occurred, set isLoading to false
		  }
		};
		fetchAndProcessData();
	  }, [poolID]);
	  if (isLoading) {
		return <p>Loading...</p>; // Render a loading indicator while data is being fetched
	  }

            const time = new Array();
            const hashrate = new Array();
            const difficulty = new Array();
            for (let i = 1; i < data.stats.length; i++) {
                difficulty.push(data.stats[i].networkDifficulty.toFixed(2));
                hashrate.push(data.stats[i].networkHashrate / 1000000000);
                time.push(timetodate(data.stats[i].created));
            }
            const labels = time;

            const networkHashrate = {
              labels,
              datasets: [
                {
                  label: 'Network Hashrate (GH/s)',
                    data: hashrate,
                   backgroundColor: "rgba(93, 47, 134, 0.5)",
                    borderColor: "rgba(211, 46, 157, 1)",
                    data: hashrate,
                    fill: true,
                    lineTension: 0.4
                },
              ],
            };
            const networkDifficulty = {
              labels,
              datasets: [
                {
                  label: 'Network Difficulty',
                    data: difficulty,
                        backgroundColor: "rgba(211, 46, 157, 0.5)",
                        borderColor: "rgba(93, 47, 134, 1)",
                        data: difficulty,
						fill: true,
						lineTension: 0.4
                },
              ],
            };

  return (
    <>
      {/* Container-1 */}
      <div className="container" style={{ paddingTop: 32, paddingBottom: 16 }}>
        <div className="card">
          <div className="card-header">
            <div className="card-title" id="currhash">
              <div className="row flex-nowrap">
              <div className="col flex-nowrap" style={{ color: "#FFFFFF", textAlign: "right", minWidth: "56%", fontSize: "20px" }}>
                    {'Network Hashrate:'}
              </div>
              <div className="col flex-nowrap" style={{ color: "#00c4ff", textAlign: "left", fontSize: "20px" }}>
                  {HPS(data.stats[data.stats.length-1].networkHashrate)}
              </div>
            </div>
            </div>
            </div>
           <div className="card-body"><Line options={options} data={networkHashrate} style={{ width: "100%" }}/></div>
        </div>
      </div>
      {/* Container-2 */}
      <div className="container" style={{ paddingTop: 16, paddingBottom: 32 }}>
        <div className="card">
          <div className="card-header">
            <div className="card-title" id="currdiff">
              <div className="row flex-nowrap">
              <div className="col flex-nowrap" style={{ color: "#FFFFFF", textAlign: "right", minWidth: "56%", fontSize: "20px" }}>
                    {'Network Difficulty:'}
              </div>
              <div className="col flex-nowrap" style={{ color: "#00c4ff", textAlign: "left", fontSize: "20px" }}>
                {(data.stats[data.stats.length-1].networkDifficulty).toFixed(2)}
              </div>
            </div>
            </div>
            </div>
          <div className="card-body"><Line options={options} data={networkDifficulty} style={{ width: "100%" }}/></div>
        </div>
      </div>
      {/* Container-2 END */}
</>

  );
}

export default Network;
