import { Link, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { baseURL, fetchData } from './api';
import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import Carousel from 'react-bootstrap/Carousel';
import { timetodate, timetotime, roundedtime, datetotime, timeago, HPS, refreshPage } from './main';

let i;

const Home = () => {
	//call API for data
	const [data, setData] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	  useEffect(() => {
		const fetchAndProcessData = async () => {
		  try {
			const fetchedData = await fetchData(baseURL);
			setData(fetchedData);
			setIsLoading(false); // Data has been received, set isLoading to false
		  }
		  catch (error) {
			console.log('Error:', error);
			setIsLoading(false); // Error occurred, set isLoading to false
		  }
		};
		fetchAndProcessData();
	  }, [baseURL]);
	  if (isLoading) {
		return <p>Loading...</p>; // Render a loading indicator while data is being fetched
	  }
	//get pool data from API
	const poolCount = new Set();
	const poolList = new Map();
	const supportedCoins = [];
	const connectionInfo = [];
	for (i = 0; i < data.pools.length; i++) {
		let name = data.pools[i].coin.symbol;
		let ID = name + data.pools[i].paymentProcessing.payoutScheme;
		poolCount.add(name);
		poolList.set(ID + 'port', Object.keys(data.pools[i].ports)[0]);
		poolList.set(ID + 'fee', data.pools[i].poolFeePercent);
		if (name === data.pools[i].id) {
			poolList.set(name + 'Hashrate', data.pools[i].poolStats.poolHashrate);
			poolList.set(name + 'Miners', data.pools[i].poolStats.connectedMiners);
			poolList.set(name + 'Luck', data.pools[i].poolEffort);
			poolList.set(name + 'LastBlock', data.pools[i].lastPoolBlockTime);
			poolList.set(name + 'Algorithm', data.pools[i].coin.algorithm);
			poolList.set(name + 'Symbol', data.pools[i].coin.symbol);
			poolList.set(name + 'NetHash', data.pools[i].networkStats.networkHashrate);
			poolList.set(name + 'Difficulty', data.pools[i].networkStats.networkDifficulty);
			}
		if (ID === data.pools[i].id) {
			for (const [key, value] of poolList) {
				if (key === (name + 'Hashrate')) {
					let totalhash = value + data.pools[i].poolStats.poolHashrate;
					poolList.set(name + 'Hashrate', totalhash);
				}
				if (key === (name + 'Miners')) {
					let totalminers = value + data.pools[i].poolStats.connectedMiners;
					poolList.set(name + 'Miners', totalminers);
				}
				if (key === (name + 'Luck')) {
					let avgluck = ((value + data.pools[i].poolEffort)/2);
					poolList.set(name + 'Luck', avgluck);
				}
				if (key === (name + 'Last Block')) {
					if (value > data.pools[i].lastPoolBlockTime) {
						poolList.set(name + 'LastBlock', data.pools[i].lastPoolBlockTime);
					}
					else {
					}
				}
			}
		}
		else {
		}
	}
	//parse pool map for individual coin values
	for (const value of poolCount) {
		const hashrate = HPS(poolList.get(value + 'Hashrate'));
		const miners = poolList.get(value + 'Miners') + ' Online';
		const luck = (poolList.get(value + 'Luck')*100).toFixed(2) + '%';
		let lastblock = poolList.get(value + 'LastBlock');
		const algo = poolList.get(value + 'Algorithm');
		const symbol = poolList.get(value + 'Symbol');
		const nethash = HPS(poolList.get(value + 'NetHash'));
		const difficulty = (poolList.get(value + 'Difficulty')).toFixed(2);
		const pplnsPort = poolList.get(value + 'PPLNSport');
		const soloPort = poolList.get(value + 'SOLOport');
		const pplnsFee = poolList.get(value + 'PPLNSfee');
		const soloFee = poolList.get(value + 'SOLOfee');
		if (lastblock !== undefined) {
			lastblock = timeago(lastblock);
		}
		else if (lastblock === undefined) {
			lastblock = 'N/A';
		}
		console.log(poolList);
					//supported coins
					supportedCoins.push(
						<Carousel.Item style={{ justifyContent: "center" }}>
							<div className="container" style={{ justifyContent: "center", maxWidth: 1080 }}>
							<div key={value} className="card h-80 g-2">
							  <div className="card-header">
								<img src={`./${symbol}.svg`} className="card-img" style={{ justifyItems: "center", height: 264, width: 330 }}/>
							  </div>
							  <div className="btn-group">
								<LinkContainer to={`/pool/${symbol}`}>
								<a className="btn btn-primary align-items-center justify-content-center" style={{ width: "50%" }}
								  onClick={ refreshPage }>
								  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={24} fill="currentColor" className="bi bi-bar-chart-line-fill" viewBox="0 0 16 16"><path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z" />
								  </svg>{" "}
								  Pool Stats
								</a>
								</LinkContainer>
								<LinkContainer to={`/network/${symbol}`}>
								<a
								  className="btn btn-primary align-items-center justify-content-center"
								  style={{ width: "50%" }}
								  onClick={ refreshPage }
								>
								  <svg
									xmlns="http://www.w3.org/2000/svg"
									width={32}
									height={24}
									fill="currentColor"
									className="bi bi-diagram-3-fill"
									viewBox="0 0 16 16"
								  >
									<path
									  fillRule="evenodd"
									  d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zm-6 8A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm6 0A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1z"
									/>
								  </svg>
								  &nbsp;Network Info
								</a>
								</LinkContainer>
							  </div>
							  <div className="card-body">
								<div className="row">
								  <div className="col-l">
									<div className="row flex-nowrap">
									  <div className="col flex-nowrap" style={{ textAlign: "right", padding: 3 }}>
										<div className="row flex-nowrap"><h6>Hashrate:</h6></div>
										<div className="row flex-nowrap"><h6>Miners:</h6></div>
										<div className="row flex-nowrap"><h6>Luck:</h6></div>
										<div className="row flex-nowrap"><h6>Last Block:</h6></div>
									  </div>
									  <div className="col flex-nowrap" style={{ textAlign: "left", padding: 3 }}>
										  <div className="row flex-nowrap"><h6>{hashrate}</h6></div>
										  <div className="row flex-nowrap"><h6>{miners}</h6></div>
										  <div className="row flex-nowrap"><h6>{luck}</h6></div>
										  <div className="row flex-nowrap"><h6>{lastblock}</h6></div>
									  </div>
									</div>
								  </div>
								  <div className="col-r">
									<div className="row flex-nowrap">
									  <div className="col flex-nowrap" style={{ textAlign: "right", padding: 3 }}>
										<div className="row flex-nowrap"><h6>Algorithm:</h6></div>
										<div className="row flex-nowrap"><h6>Symbol:</h6></div>
										<div className="row flex-nowrap"><h6>Nethash:</h6></div>
										<div className="row flex-nowrap"><h6>Difficulty:</h6></div>
									  </div>
									  <div className="col flex-nowrap" style={{ textAlign: "left", padding: 3 }}>
										<div className="row flex-nowrap"><h6>{algo}</h6></div>
										<div className="row flex-nowrap"><h6>{symbol}</h6></div>
										<div className="row flex-nowrap"><h6>{nethash}</h6></div>
										<div className="row flex-nowrap"><h6>{difficulty}</h6></div>
									  </div>
									</div>
								  </div>
								</div>
							  </div>
							</div>
							</div>
						</Carousel.Item>
						);

						//connection information
						connectionInfo.push(
							<tr>
								<td>
									<img
									  src={`./${symbol}.svg`}
									  className="card-img"
									  style={{ justifyItems: "center", height: 64, width: 64 }}
									/>
								</td>
								<td>
									<h6>🇺🇸 North America</h6>
									<h6>🇩🇪 Europe</h6>
									<h6>🇸🇬 Asia / Pacific</h6>
								</td>
								<td>
									<h6>us.evrpool.org</h6>
									<h6>eu.evrpool.org</h6>
									<h6>ap.evrpool.org</h6>
								</td>
								<td>
									<h5>
									  <svg
										xmlns="http://www.w3.org/2000/svg"
										width={24}
										height={24}
										fill="currentColor"
										viewBox="0 0 640 512"
									  >
										<path d="M144 160c-44.2 0-80-35.8-80-80S99.8 0 144 0s80 35.8 80 80s-35.8 80-80 80zm368 0c-44.2 0-80-35.8-80-80s35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM416 224c0 53-43 96-96 96s-96-43-96-96s43-96 96-96s96 43 96 96zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z" />
									  </svg>{" "}
									  PPLNS
									</h5>
									<h5>
									  <svg
										xmlns="http://www.w3.org/2000/svg"
										width={24}
										height={24}
										fill="currentColor"
										viewBox="0 0 448 512"
									  >
										<path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
									  </svg>{" "}
									  SOLO
									</h5>
								</td>
								<td>
									<h5>:{pplnsPort}</h5>
									<h5>:{soloPort}</h5>
								</td>
								<td>
									<h5>{pplnsFee}%</h5>
									<h5>{soloFee}%</h5>
								</td>
							</tr>
						)

					}



  return (
    <div className="container-fluid">
	<div className="container">
	<Carousel>
		{supportedCoins}
    </Carousel>
	</div>
        <>
  {/* Container-2 */}
  <div className="container" style={{ justifyContent: "center", maxWidth: 1080 }}>
    <div className="row" style={{ paddingBottom: 42 }}>
      <h1>
        <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="currentColor" className="bi bi-info-square" viewBox="0 0 16 16">
          <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
          <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
        </svg>{" "}
        Connection Information:
      </h1>
    </div>
    <div className="row row-cols-1 g-3">
		<div className="card">
          <div className="table-responsive">
            <table className="table" style={{ color: "#FFFFFF", borderColor: "#FFFFFF" }}>
              <thead>
                <tr>
                  <th>Coin</th>
                  <th>Server Location</th>
                  <th>Pool URL</th>
                  <th>Payout Mode</th>
					<th>Port</th>
					<th>Fee</th>
                </tr>
              </thead>
              <tbody id="connectionInformation">
                {connectionInfo}
              </tbody>
            </table>
          </div>
        </div>

    </div>
  </div>
  {/* Container-2 END */}
  {/* Footer */}
  <div className="container">
    <div className="row">
      <div className="col" style={{ margin: 32 }}>
        <h3>
          Trouble connecting? Try{" "}
          <a className="btn btn-primary btn-lg" href="./start.html">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              fill="currentColor"
              className="bi bi-tools"
              viewBox="0 0 16 16"
            >
              <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
            </svg>
            &nbsp;Getting Started
          </a>
          or join{" "}
          <a
            className="btn btn-lg btn-primary"
            href="https://discord.gg/ADaMmqj9Nr"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              fill="currentColor"
              className="bi bi-discord"
              viewBox="0 0 16 16"
            >
              <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
            </svg>{" "}
            Discord
          </a>{" "}
          for live support.
        </h3>
      </div>
    </div>
  </div>
  {/* Footer END */}
</>


    </div>




  );
}

export default Home;